<template>
	<v-layout v-if="model" column fill-height v-bind="$attrs">
		<v-flex shrink>
			<v-layout row justify-end align-center>
				<v-btn flat ma-0 icon @click="$emit('close')">
					<v-icon>close</v-icon>
				</v-btn>
			</v-layout>
		</v-flex>
		<v-flex shrink>
			<v-layout row align-center justify-center>
				<v-flex shrink>
					<NodeItem v-model="model" :version="documentVersion" large :show-counter="false" :show-title="false" fill-height @click="openPreview()" />
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex shrink>
			<DetailsToolBar v-model="model" :selected-folder="selectedFolder" :document-version="documentVersion" fill-height />
		</v-flex>
		<v-divider />
		<v-flex fill-height scroll-y>
			<v-list two-line>
				<RenameButton v-model="node" single-tile-display list-tile-display />
				<EditDescriptionButton v-model="node" single-tile-display />
				<FolderAutomationSelector v-model="node" list-tile-display />
				<ValidationButtons v-if="!conditionalDisplay.isMobileApp" v-model="node" single-tile-display with-validator />
				<UniqueNumberButton v-model="node" single-tile-display />
			</v-list>
			<v-list three-line>
				<!-- TODO: should we allow to edit themes when everything is common between nodes? -->
				<NodeThemeSelector v-model="model" list-tile-display />
			</v-list>
			<WModule name="workflow">
				<v-list v-if="canSeeWorkflowPanel" two-line>
					<WorkflowPanel v-show="model" list-tile-display />
				</v-list>
			</WModule>
			<v-list two-line>
				<DocumentVersions :document="document" list-tile-display :value="documentVersion" @input="changeDocumentVersion($event)" />
				<DocumentDeposit v-model="document" />
				<DocumentUpdate v-model="document" />
				<SelectionCount v-model="nodes" />
				<SelectionSize v-model="document" />
			</v-list>
		</v-flex>
	</v-layout>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import { mapState } from "vuex";

export default {
	name: 'SelectionDetails',
	components: {
		NodeItem: () => ({
			component: import('@/components/Documents/Content/NodeItem')
		}),
		EditDescriptionButton: () => ({
			component: import('@/components/Documents/Actions/Description/EditDescriptionButton')
		}),
		RenameButton: () => ({
			component: import('@/components/Documents/Actions/Rename/RenameButton')
		}),
		NodeThemeSelector: () => ({
			component: import('@/components/Documents/Actions/Themes/NodeThemeSelector')
		}),
		UniqueNumberButton: () => ({
			component: import('@/components/Documents/Actions/UniqueNumber/UniqueNumberButton')
		}),
		ValidationButtons: () => ({
			component: import('@/components/Documents/Actions/Validation/ValidationButtons')
		}),
		DetailsToolBar: () => ({
			component: import('@/components/Documents/Details/DetailsToolBar')
		}),
		DocumentVersions: () => ({
			component: import('@/components/Documents/Versions/DocumentVersions')
		}),
		FolderAutomationSelector: () => ({
			component: import('@/components/Documents/Automations/FolderAutomationSelector')
		}),
		DocumentUpdate: () => ({
			component: import('@/components/Documents/Details/DocumentUpdate')
		}),
		DocumentDeposit: () => ({
			component: import('@/components/Documents/Details/DocumentDeposit')
		}),
		SelectionSize: () => ({
			component: import('@/components/Documents/Details/SelectionSize')
		}),
		SelectionCount: () => ({
			component: import('@/components/Documents/Details/SelectionCount')
		}),
		WorkflowPanel: () => ({
			component: import('@/components/Workflows/WorkflowPanel')
		}),
	},
	mixins: [DocumentVersionsModuleGuard],
	inheritAttrs: false,
	props: {
		selectedFolder: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: Array,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			documentVersion: null
		}
	},
	computed: {
		...mapState({
			isAdmin: state => state.company?.userRoles?.isAdmin ?? false
		}),
		canSeeWorkflowPanel () {
			if (this.folder) {
				return this.isAdmin
			}

			return true;
		},
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		nodes: function () {
			return this.model.length > 1 ? this.model : null
		},
		node: function () {
			return this.model.length == 1 ? this.model[0] : null
		},
		folder: function () {
			return this.node && this.node.is_folder ? this.node : null
		},
		document: function () {
			return this.node && this.node.is_document ? this.node : null
		},
		version: function () {
			return this.document && this.documentVersion ? this.documentVersion : null
		},
		folders: function () {
			return this.nodes && this.nodes.every(node => !node.is_folder) ? this.nodes : null
		},
		documents: function () {
			return this.nodes && this.nodes.every(node => node.is_document) ? this.nodes : null
		}
	},
	watch: {
		documents: {
			handler: function () {
				this.loadDetails()
			}
		}
	},
	mounted: function () {
		this.loadDetails()
	},
	methods: {
		changeDocumentVersion: function (version) {
			if (version?.id && version?.id == this.document.latestVersion?.id) {
				this.documentVersion = null
			} else {
				this.documentVersion = version
			}
		},
		close: function () {
			this.$emit('close')
		},
		openPreview: function () {
			if (this.document) {
				const eventData = {
					accountingFirmId: this.accountingFirmId,
					vendorId: this.vendorId,
					document: this.document,
					folder: this.selectedFolder
				}
				if (this.documentVersion) {
					eventData.version = this.documentVersion
				}
				this.eventBus.emit(this.events.preview.OPEN, eventData)
			}
		},
		loadDetails: function () {
			let result = Promise.resolve()
			if (this.documents && this.documents.some(document => !document.latestVersion)) {
				const currentVendorId = this.vendorId ? this.vendorId : this.document.vendor_id
				result = Promise.all(
					this.documents.map(document => {
						return this.service.getDocumentVersions(currentVendorId, document)
					})
				)
			}
			return result
		}
	}
}
</script>
